.HouseList {
    width: 100%;
    height: auto;

    ul{
        list-style: none;
        padding:0;
        margin: 0;
    }

    li{
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 1.5em;

        .Name{
            //position: absolute;
            font-weight:normal;
            font-size: 0.75em;
        }

        .Points{
            margin: 0;
        }

        .Bar{
            display: flex;
            padding: 0.8em;
            position: relative;
            height: 80px;
            width: 0%;
            min-width: 50px;
            background-color: white;
            transition: width 0.25s ease-in-out;
            flex-direction: column;

            img{
                height: 100px;
                position: absolute;
                right: 0;
                top: 0;
            }

            &.Gryffindor{
                background-color: #a84b46;
                color: #dabd71;

                &.darken{
                    background-color: darken(#a84b46, 5%);
                }
            }

            &.Hufflepuff{
                background-color: #edcf5f;
                color: #60677a;

                &.darken{
                    background-color: darken(#edcf5f, 5%);
                }
            }

            &.Ravenclaw{
                background-color: #396486;
                color: #bc9d89;

                &.darken{
                    background-color: darken(#396486, 5%);
                }
            }

            &.Slytherin{
                background-color: #5b763f;
                color: #d3ccde;

                &.darken{
                    background-color: darken(#5b763f, 5%);
                }
            }
        }
    }
}