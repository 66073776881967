@import "~bootstrap/scss/bootstrap";

*{
    font-family: Poppins;
}

body{
    // color: white;
}

h1, h2, h3{
    font-weight: normal;
}

hr.page-heading{
    margin: 2em 0 2em 0;
}