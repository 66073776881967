.App{
}

.WinningHouse{
    font-weight: normal;
    margin-bottom: 1em;

    span{
        display: inline-block;
        //padding-left: 0.25em;
        //padding-right: 0.25em;
        //background-color: black;
        font-weight: bold;
        color: white;
    }
}