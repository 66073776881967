.PointsRemaining {
    display: block;
    width: 100%;

    .bar-wrapper{
        width: 100%;
        height: 20px;
        background-color: #eee;

        .bar{
            height: 100%;

            &.red{
                background-color: tomato;
            }
            
            &.orange{
                background-color: orange;
            }
    
            &.green{
                background-color: lightgreen;
            }
        }
    }
}
